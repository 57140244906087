import "./styles.scss"

import React from "react"

const PointsList = () => {
  return (
    <section className="location-points-list">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5">
            <strong>Stare Miasto i Rynek Główny</strong>
            <p>2,2 km / 25 minut pieszo / 10 minut samochodem</p>

            <strong>Galeria Krakowska, Dworzec PKP Kraków Główny</strong>
            <p>2,7 km / 30 minut pieszo / 8 minut samochodem</p>

            <strong>Forty Kleparz</strong>
            <p>1,5 km / 18 minut pieszo / 5 minut samochodem</p>

            <strong>Błonia Krakowskie</strong>
            <p>2,3 km / 30 minut pieszo / 10 minut rowerem</p>

            <strong>Park Jordana</strong>
            <p>1,7 km / 22 minut pieszo / 8 minut rowerem</p>

            <strong>Ikea, Galeria Bronowice, OBI</strong>
            <p>3,7 km / 35 minut pieszo / 11 minut samochodem</p>
          </div>
          <div className="col-lg-6 offset-lg-1">
            <div className="location-points-list__image">
              <img
                src={
                  require("assets/images/location-points-list-image.jpg")
                    .default
                }
                alt=""
              />
            </div>
            <strong>Kopiec Kościuszki</strong>
            <p>6,8 km / 30 minut MPK /19 minut samochodem</p>

            <strong>Park Kleparski</strong>
            <p>1,6 km / 20 minut pieszo / 5 minut rowerem</p>

            <strong>Młynówka Królewska</strong>
            <p>250 m / 3 minuty pieszo / 1 minuta rowerem</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PointsList
