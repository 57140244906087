import "./styles.scss"

import React from "react"

const TopGrid = () => {
  return (
    <section className="location-top-grid">
      <div className="container-fluid">
        <h2>Krowodrza - tu warto zamieszkać!</h2>

        <div className="row align-items-center">
          <div className="col-xl-6">
            <ul>
              <li>
                lokalizacja w centrum - kilka chwil spacerem do Rynku Głównego,
              </li>
              <li>okolica skąpana w zieleni,</li>
              <li>
                dobra oferta edukacyjna - liczne przedszkola, szkoły i uczelnie
                wyższe,
              </li>
              <li>bliskość komunikacji miejskiej</li>
              <li>szeroka dostępność lokali gastronomicznych,</li>
              <li>
                oferta kulturalna miasta na wyciągnięcie ręki - blisko do
                licznych kin i teatrów
              </li>
            </ul>
          </div>
          <div className="col-xl-2 col-sm-4">
            <div className="location-top-grid__item">
              <div className="location-top-grid__item-image">
                <img
                  src={require("assets/icons/features/time.svg").default}
                  alt=""
                />
              </div>
              <h4>Dla osób ceniących swój czas</h4>
            </div>
          </div>
          <div className="col-xl-2 col-sm-4">
            <div className="location-top-grid__item">
              <div className="location-top-grid__item-image">
                <img
                  src={require("assets/icons/features/activity.svg").default}
                  alt=""
                />
              </div>
              <h4>Dla osób żyjących aktywnie</h4>
            </div>
          </div>
          <div className="col-xl-2 col-sm-4">
            <div className="location-top-grid__item">
              <div className="location-top-grid__item-image">
                <img
                  src={require("assets/icons/features/comfort.svg").default}
                  alt=""
                />
              </div>
              <h4>Dla osób ceniących najwyższy komfort</h4>
            </div>
          </div>
        </div>

        <div className="location-top-grid__divider--100" />

        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="location-top-grid__image location-top-grid__image--1">
              <img
                src={
                  require("assets/images/location-top-grid-image-1.jpg").default
                }
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-5 offset-xl-1 col-lg-6">
            <h2>Dobry dostęp do komunikacji miejskiej i PKP</h2>
            <div className="location-top-grid__inner">
              <p>
                Mazowiecka 115 oferuje bardzo dobry dostęp do ważnych punktów
                miejskiej komunikacji. W pobliżu znajdują się ważne przystanki
                tramwajowe, autobusowe oraz stacja PKP Kraków Łobzów. Z
                najbliższych przystanków bez trudu dotrzesz w odległe miejsca
                Krakowa takie jak np. Kampus UJ na Ruczaju.
              </p>

              <strong>230 m / 3 minuty pieszo</strong>
              <p>do przystanku autobusowego "Mazowiecka"</p>

              <strong>350 m / 4 minuty pieszo</strong>
              <p>do przystanku autobusowego "Urząd Marszałkowski"</p>

              <strong>700 m / 9 minut pieszo</strong>
              <p>do przystanku tramwajowego "Biprostal"</p>

              <strong>700 m / 9 minut pieszo</strong>
              <p>do stacji PKP Kraków Łobzów</p>
            </div>
          </div>
        </div>

        <div className="location-top-grid__divider--100" />

        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <h3>Linie tramwajowe</h3>
            <div className="location-top-grid__inner">
              <p>
                Przez Krowodrzę kursuje aż 5 linii, dzięki którym z łatwością
                dotrzesz w różne rejony miasta:
              </p>
              <ul>
                <li>4 – od Wzgórz Krzesławickich do Bronowic Małych</li>
                <li>13 – od Bronowic do Nowego Bieżanowa</li>
                <li>14 – od Mistrzejowic do Bronowic Małych</li>
                <li>20 – od Małego Płaszowa P+R do Cichego Kącika</li>
                <li>64 – od os. Piastów do Bronowic Małych.</li>
              </ul>
            </div>
          </div>
          <div className="col-xl-5 offset-xl-1 col-lg-6">
            <h3>Dobry dostęp do komunikacji miejskiej i PKP</h3>
            <div className="location-top-grid__inner">
              <p>
                Przy ulicy Krowoderskich Zuchów 13 znajduje się dworzec
                autobusowy Krowodrza Górka, z którego kursują autobusy i busy
                obsługujące ruch lokalny i pobliskie miejscowości.
              </p>
            </div>
          </div>
        </div>

        <div className="location-top-grid__divider--200" />

        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="location-top-grid__image location-top-grid__image--2">
              <img
                src={
                  require("assets/images/location-top-grid-image-2.jpg").default
                }
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-5 offset-xl-1 col-lg-6">
            <h2>Usługi z wielu branż w pobliżu</h2>
            <div className="location-top-grid__inner">
              <p>
                Zaletą mieszkania w jednej z najbardziej popularnych dzielnic
                Krakowa jest dostęp do licznych punktów usługowych, w których
                zrealizujesz najważniejsze potrzeby.
              </p>
              <ul>
                <li>Przychodnie lekarskie</li>
                <li>Apteki</li>
                <li>Banki</li>
                <li>Kawiarnie</li>
                <li>Restauracje i bary</li>
                <li>Prawnicy, notariusze</li>
                <li>Poczta</li>
                <li>Paczkomaty i punkty kurierskie</li>
                <li>Salony fryzjerskie, kosmetyczne, drogerie</li>
                <li>I wiele, wiele więcej!</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="location-top-grid__divider--150"></div>

        <div className="row">
          <div className="col-lg-5">
            <h2>Łatwy dojazd do najważniejszych punktów na mapie Krakowa</h2>
            <div className="location-top-grid__inner">
              <p>
                Mieszkanie w tej części Krakowa to gwarancja łatwego dotarcia do
                najbardziej popularnych miejsc na mapie miasta. To idealna
                lokalizacja zarówno dla osób nastawionych na biznes, rodzin z
                dziećmi, a także dla studentów.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TopGrid
