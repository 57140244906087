import "./styles.scss"

import React from "react"

const Gym = () => {
  return (
    <section className="location-gym">
      <div className="container-fluid">
        <div className="row align-items-end">
          <div className="col-xl-5">
            <h2>Siłownie i ośrodki sportowe</h2>
            <p>
              Krowodrza to idealna okolica dla osób, którym zależy na dobrym
              dostępie do sportowej infrastruktury. Znajduje się tu
              kilkadziesiąt siłowni – w tym bardzo dobrze oceniana Infinity
              Fitness przy ul. Reymonta 22 oraz Fit by Step Kraków przy ul.
              Królewskiej 1. Trenujący w plenerze mogą skorzystać z siłowni na
              świeżym powietrzu przy Młynówce Królewskiej. Na terenie AGH
              znajduje się nie tylko basen, ale także boisko do gry w piłkę
              (orlik MS-AGH). Niedaleko zlokalizowany jest także stadion WKS
              Wawel. Dla miłośników tenisa dostępny jest kort tenisowy niedaleko
              Młynówki Królewskiej.
            </p>
          </div>

          <div className="col-xl-6 offset-xl-1">
            <img
              src={require("assets/images/location-gym-image.jpg").default}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Gym
