import "./styles.scss"

import React from "react"

const BottomGrid = () => {
  return (
    <section className="location-bottom-grid">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="location-bottom-grid__image">
              <img
                src={
                  require("assets/images/location-bottom-grid-image-1.jpg")
                    .default
                }
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-5 offset-xl-1 col-lg-6">
            <h2>Uczelnie, urzędy i centra biznesowe w okolicy</h2>
            <div className="location-bottom-grid__inner">
              <p>
                W okolicy znajdują się ważne centra administracyjne, biznesowe,
                a także uczelnie i szkoły wyższe. To okolica, w której szybko i
                wygodnie załatwisz swoje sprawy, a dojazd do pracy, czy szkoły
                nie będzie stanowił problemu.
              </p>

              <strong>AGH</strong>
              <p>1,3 km / 15 minut pieszo / 6 minut samochodem</p>

              <strong>Uniwersytet Jagielloński na Gołębiej</strong>
              <p>2,4 km / 30 minut pieszo / 25 minut MPK</p>

              <strong>Inspektorat ZUS</strong>
              <p>850 m / 10 minut pieszo / 2 minuty samochodem</p>

              <strong>Urząd Marszałkowski</strong>
              <p>350 m / 4 minuty pieszo / 1 minutę samochodem</p>

              <strong>Urząd Pracy Powiatu Krakowskiego</strong>
              <p>800 m / 9 minut pieszo / 2 minuty samochodem</p>
            </div>
          </div>
        </div>

        <div className="location-bottom-grid__divider--min-85" />

        <h2>Duży wybór żłobków, przedszkoli i szkół</h2>
        <div className="row flex-column-reverse flex-lg-row">
          <div className="col-xl-5 col-lg-6">
            <div className="location-bottom-grid__inner">
              <p>
                Dla rodzin z dziećmi ważny jest dostęp do szkół i przedszkoli. W
                tej lokalizacji znajduje się:
              </p>
              <ul>
                <li>Szkoła Podstawowa nr 36</li>
                <li>Szkoła Podstawowa nr 34</li>
                <li>Szkoła Podstawowa nr 106</li>
                <li>Waldorfska Szkoła Podstawowa i Gimnazjum</li>
                <li>Samorządowe Przedszkole nr 75</li>
                <li>Samorządowe Przedszkole nr 75</li>
              </ul>
            </div>
          </div>

          <div className="col-xl-6 offset-xl-1 col-lg-6">
            <div className="location-bottom-grid__image location-bottom-grid__image--1">
              <img
                src={
                  require("assets/images/location-bottom-grid-image-2.jpg")
                    .default
                }
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="location-bottom-grid__divider--150" />

        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="location-bottom-grid__image location-bottom-grid__image--2">
              <img
                src={
                  require("assets/images/location-bottom-grid-image-3.jpg")
                    .default
                }
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-5 offset-xl-1 col-lg-6">
            <h2>Wygodne codzienne zakupy</h2>
            <div className="location-bottom-grid__inner">
              <p>
                W niedalekiej odległości od Mazowieckiej 115 znajdziesz wiele
                sklepów, drogerii i hipermarketów, w których wygodnie zrobisz
                najważniejsze zakupy.
              </p>
              <p>
                W okolicy między innymi Rossman, Biedronka, Żabki, ALDI,
                Jubilat, liczne piekarnie, a także targ warzywny przy ul.
                Nowowiejskiej/Lea.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BottomGrid
