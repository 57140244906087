import React from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import {
  DescWithMap,
  TopGrid,
  PointsList,
  BottomGrid,
  Gym,
} from "page_components/location"

const Location = () => (
  <Layout seo={{ title: "Lokalizacja" }}>
    <PageHeader title="Lokalizacja" />
    <DescWithMap />
    <TopGrid />
    <PointsList />
    <BottomGrid />
    <Gym />
  </Layout>
)

export default Location
