import "./styles.scss"

import React from "react"

import Map from "components/Map"

const DescWithMap = () => {
  return (
    <section className="location-desc-with-map">
      <div className="container-fluid">
        <p>
          Mazowiecka 115, to najlepszy wybór dla osób ceniących komfort, wygodę
          oraz życie w tętniącym kulturalnym i biznesowym życiem centrum
          Krakowa. To lokalizacja idealna dla osób, którym zależy na sprawnym
          poruszaniu się po mieście i łatwym dostępie do oferty
          kulturalno-rozrywkowej, usługowej i rekreacyjnej Krakowa.
        </p>
        <Map />
      </div>
    </section>
  )
}

export default DescWithMap
